import React from "react";
import LatestWin from "./LatestWin";

const Jackpotwinner = () => {
  return (
    <section className="container jackpot-winner px-0">
      <div className="row w-100">
        <div className="col-4 home-recommend ">
          <div className="home-title">
            <img src="/assets/img/icon-recommand.png" alt="recommand" />
            <h3 className="home-title-text">Recommend</h3>
          </div>
          <div className="recommend-list">
            <div className="recommend-item">
              <div className="recommend-title">Slot</div>
              <div className="recommend-desc">Mines &amp; Crash</div>
              <img
                className="recommend-hot-icon"
                src="/assets/img/icon-hot.png"
                alt="recommand"
              />
              <div className="recommend-cover egame" title="slots"></div>
            </div>
            <div className="recommend-item">
              <div className="recommend-title">Fishing</div>
              <div className="recommend-desc">Mines &amp; Crash</div>
              <img
                className="recommend-hot-icon"
                src="/assets/img/icon-hot.png"
                alt="recommand"
              />
              <div className="recommend-cover fishing" title="fishing"></div>
            </div>
            <div className="recommend-item">
              <div className="recommend-title">Live</div>
              <div className="recommend-desc">Mines &amp; Crash</div>
              <img
                className="recommend-hot-icon"
                src="/assets/img/icon-hot.png"
                alt="recommand"
              />
              <div className="recommend-cover live"></div>
            </div>
          </div>
        </div>
        <div className="col-4 home-jackpot">
          <div className="jackpot-wrap">
            <div className="home-jp">
              <div className="home-jp-gp d-none">
                <h2>
                  AMEBA <span>Jackpot</span>
                </h2>
              </div>
              <span className="count-up home-jp-digi">
                <i>0</i>
                <i>0</i>
                <i>0</i>
                <i>0</i>
                <i>3</i>
                <i>5</i>
                <i>8</i>
                <i>8</i>
                <i>7</i>
                <i>7</i>
              </span>
              <a
                className="home-jp-btn"
                href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
              >
                Play Now
              </a>
            </div>
          </div>
        </div>
        <div className="col-4 winners">
          <div className="home-title">
            <img src="/assets/img/icon-win.png" alt="recommand" />
            <h3 className="home-title-text">Latest Winners</h3>
          </div>
          <div className="latest-win">
            <LatestWin />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Jackpotwinner;
