import React from "react";
import { BiSolidLeftArrow } from "react-icons/bi";
const EventqMenu = () => {
  return (
    <div className="event-qmenu d-flex">
      <div className="handle">
        <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
          <img src="/assets/img/svgexport-1.svg" className="scvg" alt="scvg" />
          <BiSolidLeftArrow className="icon-left" />
        </a>
      </div>
      <div className="cont">
        <div className="event-item">
          <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
            <img src="/assets/img/mission.png" alt="event-mission" />
          </a>
        </div>
        <div className="event-item" />
        <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
          <img
            src="/assets/img/event_vip.png"
            className="event-item-img"
            alt="event-vip"
          />
        </a>
        <a
          className="event-item"
          href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
        >
          <img
            src="/assets/img/event_roulette.png"
            className="event-item-img"
            alt="event-roulette"
          />
        </a>
        <div className="event-item">
          <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
            <img
              src="/assets/img/event_rank.png"
              className="event-item-img"
              alt="event-rank"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventqMenu;
