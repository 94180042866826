import React from "react";
import { slotData } from "../data/slot.data";

const Slot = () => {
  return (
    <section className="home-egame">
      <div className="home-title">
        <div className="d-flex">
          <img
            src="/assets/img/icon-egame.png"
            alt="recommand"
            className=" me-2"
          />
          <h3 className="home-title-text m-0">Slot</h3>
        </div>
        <div className="btn-more ">More</div>
      </div>

      <div className="egame-list">
        {slotData.map((item: any) => (
          <div className="game-item" key={item.id}>
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="game-cover">
                <img src={item.imgSrc} className="" alt={item.altText} />
              </div>
            </a>
            <div className="game-name">Ace Round</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Slot;
