import React from "react";

const Marqueediv = () => {
  return (
    <div className="marquee-news ">
      <div className="marquee-wrap news home-page-position">
        <div className="visible-area">
          <div
            className="marquee-content scrollLeft"
            // style="animation-delay: 2s; animation-duration: 22.81s;"
          >
            <span className="marquee-item" data-nosnippet="true">
              {" "}
              📌 [05/11/23] Congratulations j***7i on winning jackpot PHP
              328,668 on JILI playing Super Ace✅ Try it NOW❗️{" "}
            </span>
          </div>
        </div>
      </div>
      <span className="news-title">
        <i className="mps-news"></i>
      </span>
    </div>
  );
};

export default Marqueediv;
