import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { carouselData } from "../data/carouselData";
const CarouselBanner = () => {
  return (
    <section>
      <Swiper
        cssMode={true}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {carouselData.map((item: any) => (
          <SwiperSlide key={item.id} className="m-0">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <img src={item.img} alt={item.alt} className="w-100 m-0" />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default CarouselBanner;
