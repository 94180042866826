import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import { latestwin } from "../data/latestwin.data";
const LatestWin = () => {
  return (
    <div className="latest-content">
      <Swiper
        // slidesPerView={3}
        spaceBetween={15}
        freeMode={true}
        direction={"vertical"}
        // pagination={{
        //   clickable: true,
        // }}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
          // pauseOnFocus: true,
          reverseDirection: true,
        }}
        modules={[FreeMode, Pagination, Autoplay]}
        className="mySwiper text-white swiper-container h-100"
      >
        {latestwin.map((item:any) => (
          <SwiperSlide className="slide-items" key={item}>
            <div className="left">
              <div className="user-avatar">
                <img src="/assets/avatar/avatar1.jpg" alt="avatar" />
              </div>
              <div className="user-info">
                <div className="user-name">{item.name}</div>Just Won
              </div>
            </div>
            <div className="right">
              <div className="prize">{item.pricewin}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LatestWin;
