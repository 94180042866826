import React from "react";
// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Nopage from "./pages/Nopage";
import Home from "./pages/Home";

import "./styles/main.css";
import "./styles/header.css";
import "./styles/jackpot.css";
import "./styles/live.css";
import "./styles/footer.css";
import "./styles/fixed.css";

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
function App() {
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Nopage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
