import React from "react";
import { IoIosContacts } from "react-icons/io";
import { AiFillFacebook } from "react-icons/ai";
import { BsWhatsapp, BsTelegram } from "react-icons/bs";
const SideMenu = () => {
  return (
    <div className="side-menu">
      <ul style={{ listStyle: "none" }}>
        <li id="shortcut_onlinecs">
          <a
            href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            target="_blank"
            rel="noreferrer"
          >
            <IoIosContacts className="fs-3" />

            <h2>LiveChat</h2>
            <p data-cont="24/7 Service">24/7 Service</p>
          </a>
        </li>
        <li id="shortcut_im2">
          <a
            href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            target="_blank"
            rel="noreferrer"
          >
            <BsWhatsapp className="fs-3" />
            <h2>WhatsApp</h2>
            <p data-cont="24/7 Service">24/7 Service</p>
          </a>
        </li>
        <li id="shortcut_custom1">
          <a
            href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            target="_blank"
            rel="noreferrer"
          >
            <BsTelegram className="fs-3" />
            <h2>Telegram</h2>
            <p data-cont="24/7 Service">24/7 Service</p>
          </a>
        </li>
        <li id="shortcut_custom2">
          <a
            href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillFacebook className="fs-3" />
            <h2>Facebook</h2>
            <p data-cont="24/7 Service">24/7 Service</p>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideMenu;
