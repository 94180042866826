export const slotData = [
  {
    name: "Ace Round",
    imgSrc: "/assets/game/85.png",
    altText: "EVOPLAY-85",
  },
  {
    name: "Budai Reels Bonus Buy",
    imgSrc: "/assets/game/5855.png",
    altText: "EVOPLAY-5855",
  },
  {
    name: "Budai Reels",
    imgSrc: "/assets/game/5847.png",
    altText: "EVOPLAY-5847",
  },
  {
    name: "Cycle of Luck",
    imgSrc: "/assets/game/5766.png",
    altText: "EVOPLAY-5766",
  },
  {
    name: "Elven Princesses",
    imgSrc: "/assets/game/82.png",
    altText: "EVOPLAY-82",
  },
  {
    name: "Epic Gladiators",
    imgSrc: "/assets/game/101.png",
    altText: "EVOPLAY-101",
  },
  {
    name: "Fruit Super Nova",
    imgSrc: "/assets/game/5752.png",
    altText: "EVOPLAY-5752",
  },
  {
    name: "Hot Triple Sevens Special",
    imgSrc: "/assets/game/5813.png",
    altText: "EVOPLAY-5813",
  },
  {
    name: "Hot Triple Sevens",
    imgSrc: "/assets/game/5517.png",
    altText: "EVOPLAY-5517",
  },
  {
    name: "Hot Volcano",
    imgSrc: "/assets/game/5902.png",
    altText: "EVOPLAY-5902",
  },
  {
    name: "Ice Mania",
    imgSrc: "/assets/game/5740.png",
    altText: "EVOPLAY-5740",
  },
  {
    name: "Indiana's Quest",
    imgSrc: "/assets/game/79.png",
    altText: "EVOPLAY-79",
  },
  {
    name: "Inner Fire",
    imgSrc: "/assets/game/5921.png",
    altText: "EVOPLAY-5921",
  },
  {
    name: "Treasure Snipes",
    imgSrc: "/assets/game/5969.png",
    altText: "EVOPLAY-5969",
  },
  {
    name: "Wild Overlords",
    imgSrc: "/assets/game/5841.png",
    altText: "EVOPLAY-5841",
  },
  {
    name: "ANIMAL RACING",
    imgSrc: "/assets/game/22024.png",
    altText: "FC-22024",
  },
  {
    name: "CHINESE NEW YEAR 2",
    imgSrc: "/assets/game/22041.png",
    altText: "FC-22041",
  },
  {
    name: "CHINESE NEW YEAR",
    imgSrc: "/assets/game/22020.png",
    altText: "FC-22020",
  },
];
