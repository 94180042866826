export const latestwin = [
  {
    id: 1,
    name: "pen*****28",
    pricewin: "515,000",
  },
  {
    id: 2,
    name: "Ca*****28",
    pricewin: "445,000",
  },
  {
    id: 3,
    name: "Lo*****28",
    pricewin: "155,000",
  },
  {
    id: 4,
    name: "Go*****28",
    pricewin: "355,000",
  },
  {
    id: 5,
    name: "Pi*****28",
    pricewin: "55,000",
  },
  {
    id: 6,
    name: "Cal*****28",
    pricewin: "35,000",
  },
  {
    id: 7,
    name: "Kid*****28",
    pricewin: "35,000",
  },
  {
    id: 8,
    name: "Ale*****28",
    pricewin: "35,000",
  },
  {
    id: 9,
    name: "Pes*****66",
    pricewin: "35,000",
  },
  {
    id: 10,
    name: "Gab*****66",
    pricewin: "35,000",
  },
  {
    id: 12,
    name: "Ques*****66",
    pricewin: "35,000",
  },
  {
    id: 13,
    name: "Ques*****66",
    pricewin: "35,000",
  },
];
