import React from "react";
import { providerListData } from "../../data/gameProvider.data";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="footer-feature">
        <div className="feature-list main-wrap">
          <div className="feature-item">
            <img
              src="https://img.gashinzo.com/img/static/desktop/temp/home/ec62/feature1.png"
              alt="signup"
            />
            <div className="feature-info">
              <h3 className="feature-title">Register</h3>
              <div className="feature-desc">
                Creating an account is easy and fast. It allows users to keep
                using products and entilles to an account free of charge and
                with zero obligations.
              </div>
            </div>
          </div>
          <div className="feature-item">
            <img
              src="https://img.gashinzo.com/img/static/desktop/temp/home/ec62/feature2.png"
              alt="signup"
            />
            <div className="feature-info">
              <h3 className="feature-title">PLAY</h3>
              <div className="feature-desc">
                After registering an account, proceed onto betting on games in
                exchange for real money.start making bets on more than 140
                sports types with our sportsbook and 5,000+ casino games.
              </div>
            </div>
          </div>
          <div className="feature-item">
            <img
              src="https://img.gashinzo.com/img/static/desktop/temp/home/ec62/feature3.png"
              alt="signup"
            />
            <div className="feature-info">
              <h3 className="feature-title">WIN</h3>
              <div className="feature-desc">
                It's not all fun and games without the winning part,of course!
                The best part is that your wins are guaranteed to be big and
                mega here
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-main container">
        <div>
          <div className="footer-head text-capitalize">About Us</div>
          <div className="footer-links">
            <a
              data-code="aboutUs"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              About i1PERA
            </a>
            <a
              data-code="userAgreement"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              User Agreement
            </a>
            <a
              data-code="responsibleGambling"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              Responsible Gaming
            </a>
            <a
              data-code="disclaimer"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              Terms and Conditions
            </a>
            <a
              data-code="userPrivacy"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div>
          <div className="footer-head">Help &amp; Support</div>
          <div className="footer-links">
            <a
              data-code="depositHelp"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              Deposits
            </a>
            <a
              data-code="withdrawalHelp"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              Withdrawals
            </a>
            <a
              data-code="commonProblem"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              FAQs
            </a>
            <a
              data-code="contactUs"
              href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            >
              Contact Us
            </a>
          </div>
        </div>
        <div>
          <img width="55" src="/assets/img/18-logo.png" alt="18+" />
        </div>
        <div>
          <div className="footer-head">Licensed by</div>
          <div className="footer-Licenses">
            <img width="150" src="/assets/img/license.png" alt="success" />
            <div className="copy">Copyright © i1PERA Reserved</div>
          </div>
        </div>
      </div>
      <div className="footer-provider">
        <div className="container">
          <div className="provider-list-box">
            {providerListData.map((item: any) => (
              <div key={item.gp}>
                <img alt={item.altText} src={item.imgSrc} loading="lazy" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
