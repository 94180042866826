export const providerListData = [
  {
    gp: "AMEBA",
    imgSrc: "/assets/provider/ameba.png",
    altText: "AMEBA",
  },
  {
    gp: "APLUS",
    imgSrc: "/assets/provider/aplus.png",
    altText: "APLUS",
  },
  {
    gp: "AUX",
    imgSrc: "/assets/provider/aux.png",
    altText: "AUX",
  },
  {
    gp: "CMDBET",
    imgSrc: "/assets/provider/cmdbet.png",
    altText: "CMDBET",
  },
  {
    gp: "CQ9",
    imgSrc: "/assets/provider/cq9.png",
    altText: "CQ9",
  },
  {
    gp: "DG",
    imgSrc: "/assets/provider/dg.png",
    altText: "DG",
  },
  {
    gp: "DS88",
    imgSrc: "/assets/provider/ds88.png",
    altText: "DS88",
  },
  {
    gp: "EVOPLAY",
    imgSrc: "/assets/provider/evoplay.png",
    altText: "EVOPLAY",
  },
  {
    gp: "EZUGI",
    imgSrc: "/assets/provider/ezugi.png",
    altText: "EZUGI",
  },
  {
    gp: "FASTSPIN",
    imgSrc: "/assets/provider/fastspin.png",
    altText: "FASTSPIN",
  },
  {
    gp: "FC",
    imgSrc: "/assets/provider/fc.png",
    altText: "FC",
  },
  {
    gp: "FUNTA",
    imgSrc: "/assets/provider/funta.png",
    altText: "FUNTA",
  },
  {
    gp: "HACKSAW",
    imgSrc: "/assets/provider/hacksaw.png",
    altText: "HACKSAW",
  },
  {
    gp: "JDB",
    imgSrc: "/assets/provider/jdb.png",
    altText: "JDB",
  },
  {
    gp: "JILI",
    imgSrc: "/assets/provider/jili.png",
    altText: "JILI",
  },
  {
    gp: "KA",
    imgSrc: "/assets/provider/ka.png",
    altText: "KA",
  },
  {
    gp: "KINGMAKER",
    imgSrc: "/assets/provider/kingmaker.png",
    altText: "KINGMAKER",
  },
  {
    gp: "LUCKY_SPORTS",
    imgSrc:
      "/assets/provider/lucky_sports.png",
    altText: "LUCKY_SPORTS",
  },
  {
    gp: "MG",
    imgSrc: "/assets/provider/mg.png",
    altText: "MG",
  },
  {
    gp: "NLC",
    imgSrc: "/assets/provider/nlc.png",
    altText: "NLC",
  },
  {
    gp: "OD_GAMING",
    imgSrc: "/assets/provider/od_gaming.png",
    altText: "OD_GAMING",
  },
  {
    gp: "PG",
    imgSrc: "/assets/provider/pg.png",
    altText: "PG",
  },
  {
    gp: "PS",
    imgSrc: "/assets/provider/ps.png",
    altText: "PS",
  },
  {
    gp: "RELAX",
    imgSrc: "/assets/provider/relax.png",
    altText: "RELAX",
  },
  {
    gp: "RICH88",
    imgSrc: "/assets/provider/rich88.png",
    altText: "RICH88",
  },
  {
    gp: "SA",
    imgSrc: "/assets/provider/sa.png",
    altText: "SA",
  },
  {
    gp: "SEXYBCRT",
    imgSrc: "/assets/provider/sexybcrt.png",
    altText: "SEXYBCRT",
  },
  {
    gp: "SPADEGAMING",
    imgSrc: "/assets/provider/spadegaming.png",
    altText: "SPADEGAMING",
  },
  {
    gp: "SPRIBE",
    imgSrc: "/assets/provider/spribe.png",
    altText: "SPRIBE",
  },
  {
    gp: "TF",
    imgSrc: "/assets/provider/tf.png",
    altText: "TF",
  },
  {
    gp: "TP",
    imgSrc: "/assets/provider/tp.png",
    altText: "TP",
  },
  {
    gp: "UGAMING",
    imgSrc: "/assets/provider/ugaming.png",
    altText: "UGAMING",
  },
  {
    gp: "VENUS",
    imgSrc: "/assets/provider/venus.png",
    altText: "VENUS",
  },
  {
    gp: "VIA_CASINO",
    imgSrc: "/assets/provider/via_casino.png",
    altText: "VIA_CASINO",
  },
  {
    gp: "WM",
    imgSrc: "/assets/provider/wm.png",
    altText: "WM",
  },
  {
    gp: "YESBINGO",
    imgSrc: "/assets/provider/yesbingo.png",
    altText: "YESBINGO",
  },
  {
    gp: "YGG",
    imgSrc: "/assets/provider/ygg.png",
    altText: "YGG",
  },
  {
    gp: "YL",
    imgSrc: "/assets/provider/yl.png",
    altText: "YL",
  },
];
