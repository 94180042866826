import React from "react";
import CarouselBanner from "../section/CarouselBanner";
import Jackpotwinner from "../section/Jackpotwinner";
import Marqueediv from "../section/marquee";
import Slot from "../section/Slot";
import "../styles/slot.css"
import Live from "../section/Live";
const Home = () => {
  return (
    <>
      <CarouselBanner />
      <div className="main-home">
        <div className="container mx-auto ">
          <div className=" marqueediv">
            <Marqueediv />
          </div>
          <Jackpotwinner />
         <Slot/>
         <Live/>
        </div> 
      </div>
    </>
  );
};

export default Home;
