const Live = () => {
  return (
    <section className="home-egame py-2">
      <div className="home-title">
        <div className="d-flex">
          <img
            src="/assets/img/icon-live.png"
            alt="recommand"
            className=" me-2"
          />
          <h3 className="home-title-text m-0">Live</h3>
        </div>
        <div className="btn-more ">
          <a
            href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
            className="text-decoration-none text-reset "
          >
            More
          </a>
        </div>
      </div>

      <div className="egame-list">
        <ul className="home-live-list">
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic" title="via_casino">
                <img
                  className="gp-logo"
                  src="/assets/img/live/via_casino.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">VIA_CASINO</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic sexybcrt" title="sexybcrt">
                <img
                  className="gp-logo"
                  src="/assets/img/live/sexybcrt.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">SEXYBCRT</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic dg" title="DG">
                <img
                  className="gp-logo"
                  src="/assets/img/live/dg.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">DG</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic wm" title="WM">
                <img
                  className="gp-logo"
                  src="/assets/img/live/wm.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">WM</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic ezugi" title="EZUGI">
                <img
                  className="gp-logo "
                  src="/assets/img/live/ezugi.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">EZUGI</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic mg" title="MG">
                <img
                  className="gp-logo"
                  src="/assets/img/live/mg.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">MG</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic sa" title="SA">
                <img
                  className="gp-logo"
                  src="/assets/img/live/sa.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">SA</h3>
          </li>
          <li className="live-item">
            <a href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino">
              <div className="main-pic venus" title="venus">
                <img
                  className="gp-logo"
                  src="/assets/img/live/venus.png"
                  alt="provider-logo"
                />
              </div>
            </a>
            <h3 className="provider-name">VENUS</h3>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Live;
