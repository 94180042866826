export const navbarMenuData = [
  {
    name: "Slot",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Fish",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Live",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Sport",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "E-Sports",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Chess",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Lottery",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Animal",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Promo",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "APP",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
  {
    name: "Affiliate",
    link: "bj88ph.live/af/Zd946zCP/i1peraaff-casino",
  },
];
