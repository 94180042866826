// create a data caroursel
export const carouselData = [
  {
    id: 1,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/edad2424-0dec-461f-a789-7ff37ebdd017.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/b688a531-e69e-43dc-aba7-9b0a95da528a.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/94edd955-cf44-4bf9-ab9e-557a9b7a9c04.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/5ab2943b-5358-485e-9810-4c19af610c38.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/455eb28e-fc43-4f98-8d75-b6eadaef88ad.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/2f877a4a-2949-412c-aa6a-efa29217805c.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/972fe2a8-d830-4a23-826f-5656090ad5f2.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/d9bd9f40-9b89-4b70-b365-6c02ee9581ea.webp",
  },
  {
    id: 2,
    title: "First slide label",
    alt: "Some representative placeholder content for the first slide.",
    img: "/assets/carousel/b0015c6d-0910-4894-998f-9b927b2af1b6.webp",
  },
];
