import { navbarMenuData } from "../../data/navbarMenu.data";
const Header = () => {
  return (
    <header className="header-container">
      <div className="container">
        <div className="main-wrap">
          <div className="left">
            <div className="logo">
              <img src="/assets/logo.png" alt="logo" />
            </div>
          </div>
          <div className="right">
            <div className="login-wrap">
              <div className="input-wrap account">
                <input
                  type="text"
                  className="username-btn"
                  placeholder="Player ID"
                />
              </div>
              <div className="input-wrap password">
                <input
                  type="password"
                  className="password-btn"
                  placeholder="Password"
                />
                <i className="fa-regular fa-eye mps-readable fs-6"></i>
                <i className="fa-regular fa-circle-question forgot-password"></i>
              </div>
              <div className="btn-wrap">
                <a
                  className="btn-big login text-decoration-none rounded-5"
                  href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
                >
                  <div className="text-uppercase fw-bolder">Login</div>
                </a>
              </div>
              <a
                className="btn-big signup text-decoration-none rounded-5"
                href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
              >
                <div className="text-uppercase ">Join Now</div>
              </a>
            </div>
            <div className="lang">
              <div className="lang-toggle">
                <img src="/assets/united-states.png" alt="usa flag" />
                English
                <i
                  className="fa-solid fa-caret-down"
                  style={{ color: "white" }}
                ></i>
              </div>
              <div className="lang-list-wrap">
                <ul className="lang-list">
                  <li className="lang-item us">
                    <i className="flag-icon flag-icon-us flag-icon-squared"></i>
                    English
                  </li>
                  <li className="lang-item ph">
                    <i className="flag-icon flag-icon-ph flag-icon-squared"></i>
                    Tagalog
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header pt-">
        <div className="container">
          <ul className="nav">
            {navbarMenuData.map((item: any) => (
              <li className="nav-egame" key={item}>
                <div className="nav-items egame active">
                  <a
                    className=""
                    href="https://bj88ph.live/af/Zd946zCP/i1peraaff-casino"
                  >
                    <h3 className="py-2 text-capitalize">{item.name}</h3>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
